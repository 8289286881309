import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_REQUEST_FROM_GOOGLE
} from "../actions/auth";
import { USER_ERROR, USER_REQUEST } from "../actions/user";
// import apiCall from "../../utils/api";
import axios from 'axios';
import constantes from '../../constantes';

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      
      commit(AUTH_REQUEST);
      
      var bodyFormData = new FormData();
      bodyFormData.append('email', credentials.email);
      bodyFormData.append('password', credentials.password);

      axios({ 
        url: constantes.apiBaseUrl + "/login",
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: "POST"
      })
      .then(res => {
        
        if (res.data.attributes) {
          // Stockage
          localStorage.setItem('token', res.data.attributes.token);
          localStorage.setItem('expires', (new Date()).getTime() + constantes.dureeSession);
          // Configuration axios
          axios.defaults.headers['Authorization'] = res.data.attributes.token
          // Mutation
          commit(AUTH_SUCCESS, res.data.attributes);
          // Dispatch
          dispatch(USER_REQUEST);
          resolve(res.data.attributes);
        }  
      })
      .catch(err => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem("token");
        localStorage.removeItem("expires");
        reject(err);
      });
    });
  },
  [AUTH_REQUEST_FROM_GOOGLE]: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      
      commit(AUTH_REQUEST);
      
      const bodyFormData = new FormData();
      bodyFormData.append('email', credentials.email);

      axios({ 
        url: constantes.apiBaseUrl + "/login-via-google",
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: "POST"
      })
      .then(res => {
        
        if (res.data.attributes) {
          // Stockage
          localStorage.setItem('token', res.data.attributes.token);
          localStorage.setItem('expires', (new Date()).getTime() + constantes.dureeSession);
          // Configuration axios
          axios.defaults.headers['Authorization'] = res.data.attributes.token
          // Mutation
          commit(AUTH_SUCCESS, res.data.attributes);
          // Dispatch
          dispatch(USER_REQUEST);
          resolve(res.data.attributes);
        }  
      })
      .catch(err => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem("token");
        localStorage.removeItem("expires");
        reject(err);
      });
    });
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
      axios({ 
        url: constantes.apiBaseUrl + "/auth/logout",
        headers: { 'Content-Type': 'multipart/form-data' },
        method: "POST"
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          // Affichage du message
          if (err.response.data.errors && err.response.data.errors.length > 0) {
            // Dispatch
            // dispatch(USER_REQUEST);
            console.error(err.response.data.errors[0].detail);
          }
        } else {
          // Dispatch
          dispatch(USER_ERROR);
        }
      });
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};