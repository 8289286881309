import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";
import axios from 'axios';
import constantes from '../../constantes';
import Vue from "vue";

const state = { status: "", profile: {} };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.nom
};

const actions = {
  [USER_REQUEST]: ({ commit }) => {
    commit(USER_REQUEST);
    
    const token = localStorage.getItem("token");
    const expires = localStorage.getItem("expires");
    let http = null;
    
    if (token && expires > (new Date()).getTime()) {
      http = axios({ 
        url: constantes.apiBaseUrl + "/auth/me",
        headers: { 'Authorization' : token }
      });
    } else {
      http = axios({ 
        url: constantes.apiBaseUrl + "/auth/me"
      });
    }

    http
    .then(resp => {
      commit(USER_SUCCESS, resp);
    })
    .catch(() => {
      commit(USER_ERROR);
      localStorage.removeItem("token");
      // if resp is unauthorized, logout, to
      // dispatch(AUTH_LOGOUT);
      commit(AUTH_LOGOUT);
    });
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp.data);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};