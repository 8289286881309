<template>
    <div id="main" style="min-height:70vh;">
        <section id="validation">
            <div class="validation-content">
                <label class="titre" v-if="!erreur && prenom">
                  Félicitations {{ prenom | premiereLettreCapitale }} !
                </label>
                <div>
                  <span>Vous venez de valider votre inscription sur notre plateforme de covoiturage SimoneVerte.fr.</span><br />
                  <span>Vous pouvez désormais proposer un covoiturage, réserver un covoiturage et participer à toutes les autres expériences que nous proposons sur SimoneVerte.fr.</span><br />
                  <span>N'attendez plus, <router-link :to="{name: 'proposer'}"><i class="mdi mdi-account"></i>Postez dès à présent votre 1er trajet</router-link> !</span>               
                </div>
            </div> 
        </section>
    </div>
</template>

<style>
#validation {
    margin:auto;
    width: 100%;
    padding: 36px;
}

.validation-content {
    text-align: center;
}

.titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}
</style>

<script>
import router from '../router';
import axios from 'axios';
import constantes from '../constantes';

export default {
  data() {
    return {
      prenom: null,
      erreur: null,
    };
  },
  created() {
    this.erreur = false;
    
    const h = this.$route.params.hash;
    this.erreur = !h;

    if (h === 'verified-by-google') {
      this.prenom = this.$route.params.prenom;
    }
    else if (!this.erreur) {
      var bodyFormData = new FormData();
      bodyFormData.append('hash', h);

      axios({
        method: 'post',
        url: constantes.apiBaseUrl + '/register/verify',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        this.prenom = res.data.attributes.prenom;
      })
      .catch(() => { this.erreur = true; });
    }
    else {
      router.push({ name: 'erreur404' });
    }
  },
}
</script>